import {useDropzone} from 'react-dropzone'
import react, { useCallback, useEffect, useRef, useState } from 'react'
import './style.css'
import {AlertIcon, CopyTextIcon, DownloadCertIcon, FileAdd, Spinner, SubscriptionErrorIcon} from '../icons'
import { ProgressBar, } from '../progressBar';
import { sendFileRequest, sendRequest } from '../../requests';
import { BuildSegments, buildSentenceTooltipStyle } from '../../segments/segments';
import { renderToString } from 'react-dom/server';
import {logEvent} from "../../utils";
import {createScanReportPDF} from "../../certificate";


interface ITag{
    children: string;
    bordered?: boolean;
}
const Tag: react.FC<ITag> = (props) => {
    return <span className={'tag__container ' + (props.bordered ? 'tag__bordered' : "")}>{props.children}</span>
}


interface ITextarea {
    error?: string;
    text: string;
    setText: (text: string) => void;
    setFile: (file?: any) => void;
    segments: string;
}

interface IDisplayError{
    error: string;
}

function getErrorFontColor(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'rgba(125, 37, 47, 1)';
    } else {
        return 'rgba(117, 81, 24, 1)'
    }
}
function getErrorBackground(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'rgba(255, 247, 244, 1)';
    } else {
        return 'rgba(254, 247, 209, 1)'
    }
}
function getErrorBorderColor(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'rgba(241, 200, 200, 1)';
    } else {
        return 'rgba(247, 199, 82, 1)'
    }
}

function getErrorIcon(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return <AlertIcon />;
    } else {
        return <SubscriptionErrorIcon />
    }
}

export function getErrorText(errorText: string) {
    if (errorText === 'Try typing more text (>250 characters) so we can give you more accurate results') {
        return 'Not enough text. Please, type more than 250 characters.';
    }
    if (errorText === 'service isn’t available at the moment') {
        return 'Service is temporarily unavailable. Please, try again later.';
    }
    if (errorText === 'Only English is supported') {
        return 'Only English is supported';
    }
    else {
        return 'Free plan words limit reached (50,000). Get premium plan to increase your limits.'
    }
}

const DisplayError: react.FC<IDisplayError> = (props) => {
    return <div 
        className="alert__container"
        style={{
            backgroundColor: getErrorBackground(props.error),
            color: getErrorFontColor(props.error),
            borderColor: getErrorBorderColor(props.error)
        }}
    >
        {
            getErrorIcon(props.error)
        }
        <span 
            className='alert__text'
            style={{
                color: getErrorFontColor(props.error)
            }}
        >
            {getErrorText(props.error)}
        </span>
    </div>
}


const Textarea: react.FC<ITextarea> = (props) => {
    const {error, text, setText, setFile, segments} = props;
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        
        document.querySelector('#textarea')!.addEventListener("scroll", () => {
            //console.log("fuck")
            document.querySelectorAll('.tooltip__parent').forEach((e) => {
                (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(false);
                    (e as any).querySelector('div').style = ''
                    document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                        (ee as any).style = ''
                    });
            })
        })
        document.querySelectorAll('.tooltip__parent').forEach((e) => {
            (e as any).onmouseover = () => {
                if (window.getSelection()?.toString().length !== 0) return;
                try {
                    (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(true);
                    (e as any).querySelector('div').style = `position: absolute; transform: translateY(-${(e as any).parentElement.scrollTop}px)`;
                    document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                        (ee as any).style = 'background-color: white!important;'
                    });
                    (e as any).style = ''
                } catch {}
            }
            (e as any).onmouseleave = () => {
                try {
                    (e as any).querySelector('div').querySelector('div').style = buildSentenceTooltipStyle(false);
                    (e as any).querySelector('div').style = ''
                    document.querySelectorAll('.tooltip__parent').forEach((ee) => {
                        (ee as any).style = ''
                    });
                } catch {}
            }
        })

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'z') {
                event.preventDefault();
            }
        };
        if (divRef.current) {
            divRef.current.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (divRef.current) {
                divRef.current.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [segments, divRef]);

    return <div 
    className={"textarea " + (error ? 'textarea__alert-parent' : '')}
    style={error ? {
        borderColor: getErrorBorderColor(error!)
    } : {}}
>
    {
        error ?
        <DisplayError error={error}/> : <></>
    }
    {
        segments ?
        <div
        contentEditable={true}
        id="textarea"
        style={{height: 'calc(100% - 60px)', overflow: 'scroll'}}
        className={
            error?.length ?
            'textarea__alert-container' : ''
        }
        ref={divRef}
        onInput={(e: any) => {
            if (e.ctrlKey && e.key === 'z') {
                return;
            }
            var currentText = e.currentTarget.innerText.slice(0, 5000);
            if (currentText === '\n') {
                setText('');
            } else {
                setText(currentText);
            }
            setFile(undefined);
        }}
        dangerouslySetInnerHTML={{__html: renderToString(<BuildSegments data={segments as any}/>)}}
    ></div> : <textarea onChange={(e) => {
            setText(e.target.value.slice(0, 5000));
            setFile(undefined);
        }}
        value={text}
        id='textarea'
        placeholder='Enter text or upload document to run scan (currently only English supported)'
        ></textarea>
    }
    
</div>
}

interface ITextareaWidgets{
    setFile: (file: any) => void;
    file: any;
    setText: (text: string) => void;
    text: string;
}

const TextareaWidgets: react.FC<ITextareaWidgets> = (props) => {
    const {setFile, file, setText, text} = props;
    const onDrop = useCallback((acceptedFiles: any) => {
        setFile(
            acceptedFiles[0]
        );
    }, []);
    const accept = {
        // 'application/pdf': ['.pdf'],
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ['.docx'],
        'text/plain': ['.txt']
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept, maxSize: 5242880/5});
    return <div className='drop__container'>
    <div className="poschange__1">
        <div {...getRootProps()} className={'filedrop__container ' + ( isDragActive ? 'filedrop__container-active' : "")}>
            <input {...getInputProps()} />
            <div className="filedrop__content" style={{borderRadius: '10px'}}>
                <FileAdd />
                <span className='upload__head'>
                    {
                        file ?
                        (file as any).path :
                        isDragActive ?
                        "Drag document here" :
                        "Click to upload document"
                    }
                </span>
                <div className="tags">
                    {/* <Tag>PDF</Tag>
                    <Tag>DOCX</Tag> */}
                    <Tag>TXT</Tag>
                    <Tag bordered>{"<1 MB"}</Tag>
                </div>
            </div>
        </div>
    </div>
    <div className="filedrop__container or__container">
        or
    </div>
    <div className="poschange__2">
        <div className='filedrop__container filedrop__container1' onClick={() => {
            navigator.clipboard.readText().then((e) => {
                setText(text+e);
            });
        }}>
            <div className="filedrop__content">
                <CopyTextIcon />
                <span className='upload__head'>
                    Click to paste text
                </span>
                <div className="tags">
                    {/* <Tag>PDF</Tag>
                    <Tag>DOCX</Tag> */}
                    <Tag bordered>{">250 characters"}</Tag>
                </div>
            </div>
        </div>
    </div>
</div>
}

interface ITextareaControll {
    text: string;
    setLoading: (status: boolean) => void;
    setPercents: (value: number) => void;
    setError: (value: string) => void;
    file: any;
    setSegments: (value: any) => void;
}

const TextareaControll: react.FC<ITextareaControll> = (props) => {
    const {text, setLoading, setPercents, setError, file, setSegments} = props;
    
    const sendDataToBackend = (deep: boolean) => {
        setLoading(true);
        setPercents(-1);
        setError('');
        if (file) {
            sendFileRequest(file).then((e) => {
                setPercents(Number.parseFloat(e) * 100);
                setLoading(false);
                if (Number.isNaN(Number.parseFloat(e))) {
                    setError(e);
                }
                window.dispatchEvent(new Event("storage"));
            })
        } else {
            sendRequest(text, deep).then((e) => {
                setLoading(false);

                if (e.answer != null) {
                    setPercents((e.answer as any) * 100);
                    if (deep) {
                        setSegments(e.segments);
                    } else {
                        setSegments('');
                    }
                }
                else {
                    setError(e.error);
                }
                window.dispatchEvent(new Event("storage"));
            })
        }
    }


    return <div className="textarea__controll">
    <div className="words__count">
        <div className="words">
            {text.length} / 5000 characters
        </div>
        <ProgressBar 
            progress={text.length / 5000 * 100}
        />
    </div>
    <div className="fd__btns">
        <button className='primary fd__button' onClick={() => {
            sendDataToBackend(false);
            logEvent('Simple scan');
        }}>
            Simple scan
        </button>
        <button className='primary fd__button' onClick={() => {
            sendDataToBackend(true);
            logEvent('Deep scan');
        }}>
            Deep scan
        </button>
    </div>
    
</div>
}

interface IScanContainer{
    loading: boolean;
    percents: number;
}


const ScanContainer: react.FC<IScanContainer> = (props) => {
    const {loading, percents} = props;
    return <div className='scan__container'>
        <div className="scan-container__content">
            <div className="s-circle human-written"></div>
            <span>Human-written</span>
        </div>
        <div className="scan-container__content">
            <div className="s-circle mixed"></div>
            <span>Mixed</span>
        </div>
        <div className="scan-container__content">
            <div className="s-circle ai-gen"></div>
            <span>AI-generated</span>
        </div>
</div>
}

interface ISpeedometr{
    score: number;
}

const Speedometr: react.FC<ISpeedometr> = (props) => {
    const {score} = props;
    return <div className='speedometr__container'>
        <img src="/speedometr.png" alt="" width={155} />
        <img src="/speedometr__arrow.png" alt="" width={70} className='speed__arrow' 
            style={{
                transform: `translateY(-7px) translateX(9.5px) rotate(${(score/100) * 180}deg)`
            }}
        />
        <div className='speedometr__text'>
            <span>Human</span>
            <span>AI</span>
        </div>
    </div>
}

export const FileDrop: react.FC = () => {
    const [file, setFile] = react.useState();
    const [text, setText_] = react.useState('');
    const [scannedText, setTextFinal] = react.useState('');

    const [loading, setLoading] = useState(false);
    const [percents, setPercents_] = react.useState(-1);
    const [error, setError] = useState('');
    const [segments, setSegments] = useState('');

    const setText = (text: string) => {
        setText_(text)
        setPercents_(-1)
    }
    const setPercents = (value: number) => {
        setPercents_(value)
        setTextFinal(text)
    }

    useEffect(() => {
        document.addEventListener('keydown', function(event) {
            const ta = document.getElementById('textarea')
            if (document.activeElement === ta){
                return;
            }
            if ((event.metaKey && event.key === 'v') ||  (event.ctrlKey && event.key === 'v')) {
                navigator.clipboard.readText().then((e) => {
                    setText(text+e);
                });
            }
        });
        if (!text.split('\n').join('').length) {
            setSegments('');
        }
    }, [text, setText]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('text')) {
            setText(urlParams.get('text')!)
        }
    }, []);
    const certAvailable = !loading && percents !== -1
    return <div className='uploader__container'>
        <div>
            <Textarea 
                text={text}
                setText={setText}
                setFile={setFile}
                error={error}
                segments={segments}
            />
            {
                text.length ? 
                <></> :
                <TextareaWidgets
                    setFile={setFile}
                    setText={setText}
                    file={file}
                    text={text}
                />
            }
            <TextareaControll 
                text={text}
                setError={setError}
                setLoading={setLoading}
                setPercents={setPercents}
                setSegments={setSegments}
                file={file}
            />
            <ScanContainer 
                loading={loading}
                percents={percents}
            />
        </div>
        <div className='result__container'>
            {
                loading && percents === -1 ?
                <div className="spin__container-b">
                    <div className="spin">
                        <Spinner />
                    </div>
                    <div className="spin__container">
                        Scanning...
                    </div>
                </div> :
                Number.isNaN(percents) || percents === -1 ?
                <span className='result__percent'>%</span> : 
                <span className='result__percent-result'>{percents.toFixed(0)}% of AI</span>
            }
            
            <Speedometr score={Math.max(0, percents)}/>
            {
                (Number.isNaN(percents) || percents === -1) && !loading ?
                <span className='result__results'>Run scan to see the results</span> : 
                !loading ?
                    percents < 40 ?
                    <div className="spin__container-b human__container-out">
                        {/* <div className="">
                            <HumanIcon />
                        </div> */}
                        <div className="human__container">
                            Human-written
                        </div>
                    </div> :
                    percents < 60 ?
                    <div className="spin__container-b mixed__container-out">
                        {/* <div className="">
                            <HumanIcon />
                        </div> */}
                        <div className="mixed__container">
                            Mixed
                        </div>
                    </div> :
                    <div className="spin__container-b ai-gen__container">
                        <div className="red-container">
                            AI-generated
                        </div>
                    </div> :
                    <></>
            }
            <button style={{marginTop: "auto", display: "flex"}} className={(certAvailable && "primary" || "secondary") + ' fd__button'} onClick={() => {
                if(certAvailable) {
                    logEvent('Download certificate');
                    createScanReportPDF(text, percents, 100-percents, !!segments.length)
                }
            }}>
                <DownloadCertIcon/>
                <div style={{marginLeft: "5px"}}/>Certificate
            </button>
            <div className={"certificate_footer"}>

            </div>

        </div>
    </div> 
}